body{
background-color: #F4F4F4;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23f4f4f4'/%3E%3Cstop offset='1' stop-color='%23f4f4f4' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23f4f4f4'/%3E%3Cstop offset='1' stop-color='%23f4f4f4' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23f4f4f4'/%3E%3Cstop offset='1' stop-color='%23f4f4f4' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23F4F4F4'/%3E%3Cstop offset='1' stop-color='%23F4F4F4' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23F4F4F4'/%3E%3Cstop offset='1' stop-color='%23F4F4F4' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23F4F4F4'/%3E%3Cstop offset='1' stop-color='%23F4F4F4' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
}



.logo-login{
    width:100%;
    height:70px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.btn-primary-purple{
    background-color: #1D1D1B !important;
    border-radius: 18px !important;
}

.bJnmUd.Mui-focused, .hODUSE, .hoCmyS.Mui-checked{
    color: #1D1D1B !important;
}
.knJUav {
    border-color: #1D1D1B !important;
}

